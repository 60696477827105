.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.outline_textarea {
    margin-top: 10px;
    font-family: inherit;
    font-size: 18px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    resize: vertical; /* Allow vertical resizing only */
    
    &:focus {
      outline: none; // Remove default focus outline
      border-color: #7F56D9; // Change border color on focus
      box-shadow: 0 0 0 2px rgba(127, 86, 217, 0.2); // Add a subtle box shadow on focus
    }
  }

