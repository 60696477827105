@import "../../../styles/commonStyles/mixins.scss";
@import "../../../styles/commonStyles/variables.scss";

#PanelHome {
  margin: 3rem 3.2rem;

  @media screen and (max-width: 767px) {
    margin: 3rem 2rem;
  }

  h2 {
    color: #101828;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.8rem;
    margin-bottom: 5.5rem;
  }

  .card_blk {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 4rem;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      gap: 3rem;
    }

    .wizard_card, .document_card {
      max-width: 33rem;
      border-radius: 1rem;
      border: 1px solid #766EEC;
      transition: all ease-in-out 0.3s;
      height: 15.5rem;
      background: #FFF;
      padding: 3.5rem 1.5rem;
      cursor: pointer;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease-in-out;

      &:hover {
        background: linear-gradient(94deg, #B145A7 0%, #4745B1 100%);
        border: none;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

        .card_body .text_blk {
          h4,
          p {
            color: #FFF;
          }
        }
      }

      .card_body {
        display: flex;
        
        .icon_blk, .icon_sec {
          width: 8.4rem;
          min-width: 8.4rem;
          height: 8.4rem;
        }

        .text_blk {
          margin-left: 2rem;

          h4 {
            color: #434548;
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 1rem;
            transition: color 0.3s ease-in-out;
          }

          p {
            color: #7D7D8B;
            font-size: 1.4rem;
            font-weight: 400;
            transition: color 0.3s ease-in-out;
          }
        }
      }
    }
  }

  .document_sec {
    margin-top: 4rem;

    .head {
      display: flex;
      justify-content: space-between;

      .left_sec {
        h4 {
          color: #101828;
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 2.8rem;
          margin-bottom: 0.5rem;
        }

        span {
          color: #475467;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 2rem;
        }
      }

      .view_btn {
        border: transparent;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        color: #475467;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2rem;
      }

    }

    .document_card_blk {
      display: flex;
      gap: 2rem;
      align-items: center;
      justify-content: center;
      margin-top: 2.5rem;

      .document_card {
        position: relative;
        border-radius: 0.8rem;
        border: 1px solid #EAECF0;
        background: #FFF;
        padding: 2rem 1.5rem;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        transition: all 0.3s ease-in-out;

        &:hover {
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        }

        h2 {
          color: #101828;
          font-size: 1.6rem;
          font-weight: 600;
          line-height: 2.8rem;
          margin-bottom: 0.5rem;
        }

        .date {
          color: #475467;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 2rem;
        }

        .info_icon {
          position: absolute;
          top: 20px;
          right: 15px;
        }
      }
    }
  }

  .upgrade_plan {
    border-radius: 1.6rem;
    background: #F9FAFB;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6.4rem;
    margin-top: 6.4rem;
    
    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 4rem;
      gap: 2rem;
    }
  
    .left_sec {
      h3 {
        color: #101828;
        font-size: 3rem;
        font-weight: 600;
        line-height: 3.8rem;
        
        @media screen and (max-width: 768px) {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }
  
      p {
        color: #475467;
        font-size: 2rem;
        font-weight: 400;
        line-height: 3rem;
        
        @media screen and (max-width: 768px) {
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
      }
    }
  
    .upgrade_btn {
      padding: 1.2rem 2rem;
      border-radius: 0.8rem;
      border: 1px solid #7F56D9;
      background: #6941C6;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      color: #FFF;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.4rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      
      @media screen and (max-width: 768px) {
        font-size: 1.4rem;
        padding: 1rem 1.6rem;
        line-height: 2rem;
      }
  
      &:hover {
        background-color: #5a36b8;
        box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.1);
      }
    }
  }
  
}
