#SelectedCampaign {
  margin: 3rem 3.2rem;

  @media screen and (max-width: 767px) {
    margin: 3rem 2rem;
  }

  h2 {
    color: #101828;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.8rem;
    margin-bottom: 5.5rem;
  }

  .inner {
    margin-top: 6rem;

    .campaign_btn {
      margin-left: auto;
      display: flex;
      width: 15.9rem;
      padding: 1rem 1.6rem;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
      border-radius: 0.8rem;
      border: 1px solid #7f56d9;
      background: #7f56d9;
      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: #fff;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2rem;
    }

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn_zapier {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .zapier-button {
          margin-top: 1rem;
          margin-left: auto;
          display: flex;
          width: 15.9rem;
          padding: 1rem 1.6rem;
          justify-content: center;
          align-items: center;
          gap: 0.8rem;
          border-radius: 0.8rem;
          border: 1px solid #7f56d9;
          background: #7f56d9;
          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: #fff;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 600;
          line-height: 2rem;
        }

        .disabled {
          background: #475467;
          margin-top: 1rem;
          margin-left: auto;
          display: flex;
          width: 15.9rem;
          padding: 1rem 1.6rem;
          justify-content: center;
          align-items: center;
          gap: 0.8rem;
          border-radius: 0.8rem;
          border: 1px solid #7f56d9;
          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: #fff;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 600;
          line-height: 2rem;
        }
      }

      .left_blk {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h3 {
          color: #000;
          font-size: 2.2rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.022rem;
          // padding-left: 1.8rem;
        }

        .selected {
          color: #000;
          font-size: 1.5rem;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.015rem;
        }
      }

      .right_sec {
        display: flex;
        align-items: center;
        margin-top: 0.6rem;

        .input_blk {
          max-width: 22.381rem;
          height: 3.8rem;
          border-radius: 1rem;
          background: #f9fbff;
          position: relative;
          border: transparent;
          margin-bottom: 0;
          margin-right: 1.6rem;

          input {
            background-color: #f9fbff;
            border: transparent;
            height: 100%;
            padding-left: 4rem;

            &::placeholder {
              color: #b5b7c0;
              font-size: 1.2rem;
              font-weight: 400;
              letter-spacing: -0.012rem;
              padding-left: 0.5rem;
            }
          }

          i {
            color: rgba(126, 126, 126, 1);
            font-size: 2rem;
          }

          .icon {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 2.4rem;
            height: 2.4rem;
          }
        }

        .sort_blk {
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: #b5b7c0;
          border-radius: 1rem;
          background: #f9fbff;
          max-width: 15.9568rem;
          height: 3.8rem;
          padding: 0 1.5rem;

          select {
            border: transparent;
            background-color: initial;
            max-width: 7rem;
            font-size: 1.2rem;
            color: #000;

            &:active,
            :focus,
            :focus-within,
            :focus-visible,
            :hover {
              border: transparent;
              outline: none;
              box-shadow: initial;
            }
          }
        }
      }
    }

    .document_sec {
      margin-top: 2.9rem;

      .document_card_blk {
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        gap: 2rem;
        margin-top: 2.5rem;

        .document_card {
          position: relative;
          flex: 0 0 30%;
          border-radius: 0.8rem;
          border: 1px solid #eaecf0;
          background: #fff;
          padding: 2rem 1.5rem;
          // cursor: pointer;

          h2 {
            color: #101828;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 2.8rem;
            margin-bottom: 0.5rem;
            max-width: 30rem;
            width: 100%;
            cursor: pointer;
          }

          .date {
            color: #475467;
            /* Text sm/Regular */
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2rem;
            cursor: pointer;
          }

          .info_icon {
            position: absolute;
            top: 20px;
            right: 15px;
          }
        }
      }
    }
  }
}