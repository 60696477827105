@import "../../../styles/commonStyles/variables";

.text-editor-package {
	color: #000;
}

.rdw-block-wrapper {
	color: #000;
}

.rdw-fontfamily-wrapper {
	color: #000;
}

.rdw-fontsize-wrapper {
	color: #000;
}

.rdw-editor-main {
	border: 1px solid black;
	height: calc(100vh - 2vh) !important;
	// height: 100vh;
	border-radius: 20px !important;
	border: 2px solid rgba(204, 208, 212) !important;
	box-shadow: (0px 10px 30px rgba(216, 216, 216, 0.5)) !important;
	padding: 2rem;
}

.rdw-editor-main::-webkit-scrollbar {
	//   display: none;
	//   color: #000;
	//   max-width: 20px;
}

.text-editor-package.rdw-editor-main {
	// height: 93vh;
}

// .DraftEditor-root {
//   padding: 0;
//   margin-right: 15px;
//   margin-left: 15px;
// }

.rdw-option-wrapper[title]:hover {
	content: "Clear Formatting" !important;
}

.rdw-editor-toolbar {
	font-size: 1.7rem !important;
	margin-bottom: 15px !important;
	border-radius: 10px !important;
	border: 2px solid rgb(226, 236, 249, 0.5) !important;
	box-shadow: (0px 10px 20px rgba(216, 216, 216, 0.5)) !important;
	display: flex !important;
	align-items: center !important;
	justify-content: space-between !important;
	padding: 1rem !important;
}

.rdw-option-wrapper {
	// margin-left: 8px !important;
	min-width: 22px !important;
	height: 25px !important;
}

#editor {
	padding: 10rem 2rem 10rem 5rem;
	width: 100%;
	min-height: 100vh;

	h1 {
		color: #101828;
		font-size: 3rem;
		font-weight: 600;
		line-height: 3.8rem;
	}
	// margin-bottom: 2rem;

	.rdw-editor-wrapper {
		// height: 100%;
		padding-bottom: 20px;
	}
}

.summary_btn {
	height: 5rem;
	width: 30rem;
	min-width: 30rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $secondary_color;
	color: $white;
	font-size: 2.1rem;
	line-height: 2.7rem;
	font-weight: 500;
	border: transparent;
	border-radius: 0.8rem;
	// margin: 0 3rem;
	margin: 3rem auto 0;
	z-index: 10;
}

.article-textarea-input {
	min-width: 150px;
}

// .rdw-remove-wrapper.rdw-option-wrapper:hover {
//   div[title] {
//     content: "Clear Formatting" !important;
//   }
// }
